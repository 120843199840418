import axios from 'axios'

export default {
    namespaced: true,

    state: {
        user: {},
        loading: false,
        authenticated: false,
        unvaccinatedPets: [],
    },

    getters: {
        authenticated (state) {
            return state.authenticated
        },

        user (state) {
            return state.user
        },

        customer (state) {
            return state.user.customer
        },

        loading (state) {
            return state.loading
        },
        hasVerifiedEmail(state)  {
            return state.user.hasVerifiedEmail ?? false;
        },
        hasActiveSubscription(state)  {
            return state.user.has_active_subscription ?? false;
        },
        subscriptionsAvailable(state)   {
            return state.user.properties_offer_subscription;
        },
        properties(state)  {
            return state.user.customer.properties ?? [];
        },
        unvaccinatedPets(state) {
            return Object.values(state.user.unvaccinated_required_pathogens);
        }
    },

    mutations: {
        SET_AUTHENTICATED (state, value) {
            state.authenticated = value
        },

        SET_USER (state, value) {
            state.user = value
        },

        SET_LOADING (state, value) {
            state.loading = value
        },
        SET_USER_HAS_ACTIVE_SUBSCRIPTION (state, value) {
            state.user.has_active_subscription = value
        },
    },

    actions: {
        async signIn ({dispatch}, credentials) {
            await axios.get('/sanctum/csrf-cookie')
            // eslint-disable-next-line no-useless-catch
            try {
                await axios.post('/login', credentials)
                await dispatch('me')
            }
            catch (error)    {
                throw error;
            }

        },
        async resetPassword ({ dispatch} ,credentials) {
            await axios.get('/sanctum/csrf-cookie')
            let status = true;
            try {
                await axios.post('/password/email', credentials)
            }
            catch (error)    {
                dispatch('toast/trigger', {text: error.response.data.errors.email[0], color: "error"}, {root: true})
                status = false;
            }

            return status;

        },
        async setPassword ({ dispatch} ,credentials) {
            await axios.get('/sanctum/csrf-cookie')
            let status = true;
            try {
                await axios.post('/password/reset', credentials)
            }
            catch (error)    {
                dispatch('toast/trigger', {text: error.response.data.errors.password[0], color: "error"}, {root: true})
                status = false;
            }

            return status;

        },

        // eslint-disable-next-line no-empty-pattern
        async register ({} ,credentials) {
            await axios.get('/sanctum/csrf-cookie')

            return await axios.post('/register', credentials)

        },

        async resendVerificationEmail () {
            return await axios.post('/api/user/verification/resend')
        },
        // eslint-disable-next-line no-empty-pattern
        async verifyEmail ({}, payload) {
            return await axios.get('/email/verify/' + payload.id + '/' + payload.hash,{
                params: {
                    expires: payload.expires,
                    signature: payload.signature,
                }
            })
        },

        async signOut ({ dispatch }) {
            try {
                await axios.post('/logout')
                dispatch('reset');
                return true;
            }
            catch (error) {
                return false;
            }

        },

        reset ({ commit }) {
            commit('SET_AUTHENTICATED', false);
            commit('SET_USER', {});
            return true;
        },

        me ({ commit }) {
            return axios.get('/api/me').then((response) => {
                commit('SET_AUTHENTICATED', true)
                commit('SET_USER', response.data.data)
                return true;
            }).catch((error) => {
                commit('SET_AUTHENTICATED', false)
                commit('SET_USER', {})
                throw error;
            })

        },
        setProperty ({ commit }, property)
        {
            commit('SET_ACTIVE_PROPERTY', property)
            localStorage.property = JSON.stringify(property);
        },
        startLoading ({ commit })
        {
            commit('SET_LOADING', true)
        },
        stopLoading ({ commit })
        {
            commit('SET_LOADING', false)
        },

        activateSubscription ({ commit })
        {
            commit('SET_USER_HAS_ACTIVE_SUBSCRIPTION', true)
        },
    }
}
