import axios from 'axios';
import store from "@/store";

//TODO fix class inheritance - as cannot extend baseAPIrepository more than once in this project - as such copying all methods from baseAPIrepository
export default class SubscriptionsRepository	{

	static resource() { return 'subscriptions'; }

    static getAvailableTypes(propertyId) {
        return axios.get(this.prefix(propertyId) + this.resource() +'/available-types').then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static getStripeBillingPortalSessionURL() {
        return axios.get(this.prefix() + this.resource() +'/billing-portal').then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }


    //Copied from BaseAPIRepository
    static prefix(propertyId = null) {
        if(propertyId === null) {
            return '/api/user/';
        }
        else {
            return '/api/user/property/' + propertyId + '/';
        }

    }

    static formatIndexParams(options = null, search = null)    {
        let params = {};
        if(options !== null)
        {
            let sortPrefix = options.sortDesc[0] ? '-' : '';
            let sort = options.sortBy[0] == null ? '' : sortPrefix + options.sortBy[0];
            params = {
                params: {
                    page: options.page,
                    itemsPerPage: options.itemsPerPage,
                    sort: sort,
                    search: (search === null) ? '' : encodeURIComponent(search),
                }
            };
        }
        return params;
    }

    static index(options = null, search = null) {

        let params = this.formatIndexParams(options, search)

        return axios.get(this.prefix() + this.resource(), params).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static get(id) {
        return axios.get(this.prefix() + this.resource() +`/${id}`).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static create(propertyId, payload) {
        // eslint-disable-next-line no-unused-vars
        return axios.post(this.prefix(propertyId) + this.resource(), payload).then((response) => {
            store.dispatch('toast/trigger', {text: "You have successfully created your membership", color: "success"}, {root: true});
            return true;
        }).catch((error) => {
            this.catchError(error);
            return false;
        })
    }

    static update(id, payload) {
        return axios.put(this.prefix() + this.resource() + `/${id}`, payload).then((response) => {
            store.dispatch('toast/trigger', {text: "The item was successfully updated", color: "success"}, {root: true});
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static delete(id) {
        return axios.delete(this.prefix()+ this.resource() + `/${id}`).then((response) => {
            return response.data;
        }).catch((error) => {
            this.catchError(error);
        })
    }

    static catchError(error = null)  {
        let errorMessage ="An error has occurred. Please try again";
        if(error !== null && error.response && error.response.status === 400) {
            errorMessage = error.response.data.message;
        }
        store.dispatch('toast/trigger', {text: errorMessage, color: "error"}, {root: true})
    }


}